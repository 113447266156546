import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="unique-options"
export default class extends Controller {
  static targets = ["select"]

  connect() {
    this.updateOptions(); // Initialize on page load
  }

  updateOptions() {
    const selectedOptions = this.getSelectedOptions();

    this.selectTargets.forEach(select => {
      const currentValue = select.value;

      // Loop through each option in the select dropdown
      Array.from(select.options).forEach(option => {
        // Disable options that have been selected in other selects and are not the current one
        if (selectedOptions.includes(option.value) && option.value !== currentValue) {
          option.disabled = true;
        } else {
          option.disabled = false;
        }
      });
    });
  }

  getSelectedOptions() {
    // Gather selected options across all select dropdowns
    return this.selectTargets
        .map(select => select.value)
        .filter(value => value !== ""); // Filter out unselected values
  }

  selectChanged() {
    // When a select dropdown changes, re-run the update logic
    this.updateOptions();
  }
}
